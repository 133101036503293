import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import * as requestToApi from "./WalletCRUD";
import { walletSlice } from "./WalletSlice";

const { actions } = walletSlice;
// --topupManual--
export const topupManualUI = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .walletUICall(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.topupManualUIFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: topupManualUIFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const topupManualRequest = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .atmDepositRequest(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.topupManualRequestFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: topupManualRequestFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
export const topupManualCancel = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .walletUICall(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.topupManualCancelFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: topupManualCancelFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
// --topupGateway--
export const topupGatewayUI = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .walletUICall2(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.topupGatewayUIFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: topupGatewayUIFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const topupGatewayRequest = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .walletUICall2(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.topupGatewayRequestFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: topupGatewayRequestFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

// --withdraw--
export const bankCardList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .walletUICall(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.bankCardListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: bankCardListFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const addBankCardList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .walletUICall(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.addBankCardListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: addBankCardListFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
export const removeBankCardList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .walletUICall(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.removeBankCardListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: removeBankCardListFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
export const withdrawUIList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .walletUICall2(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.withdrawUIListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: withdrawUIListFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
export const withdrawRequestList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .walletUICall2(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.withdrawRequestListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: withdrawRequestListFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
export const withdrawListCancel = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .walletUICall2(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.withdrawListCancelFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: withdrawListCancelFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
// history
export const topupWithdrawPendingList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .walletUICall2(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(
            actions.topupWithdrawPendingListFetched({ data: response.data })
          );
          return response;
        }
      } else {
        throw new Error("Error api: topupWithdrawPendingListFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
export const topupWithdrawDetailsList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .walletUICall2(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(
            actions.topupWithdrawDetailsListFetched({ data: response.data })
          );
          return response;
        }
      } else {
        throw new Error("Error api: topupWithdrawDetailsListFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
//transfer
export const transferToMember = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .transferTo(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.transferToMemberFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: transferToMemberFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
