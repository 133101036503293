import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/LotteryAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import SweetAlert2 from "react-sweetalert2";
import Skeleton from "react-loading-skeleton";
import { LotteryHistoryTypeConst } from "../../../../constants/LotteryNavConst";
import { LotteryOnHoldDetail } from "../lotteryHistory/lotteryOnHoldDetail/LotteryOnHoldDetail";

export const LotteryOnHold = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [lotteryOrderID, setLotteryOrderID] = useState("");
  const [lotteryItemID, setLotteryItemID] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [lotteryType, setLotteryType] = useState(
    LotteryHistoryTypeConst.SLIPHISTORY
  );
  const [swalProps, setSwalProps] = useState({});
  const [viewType, setViewType] = useState("");
  const [lotteryOnHoldDetailsVisible, setLotteryOnHoldDetailsVisible] =
    useState(false);

  const lotteryState = useSelector(({ lottery }) => lottery, shallowEqual);

  useEffect(() => {
    const queryParams = {
      action: "seasononholdlist",
      viewtype: viewType,
    };
    dispatch(actions.callSeasonOnHoldList(queryParams));
  }, []);

  const handleViewTypeChange = (value) => {
    setViewType(value);
    const queryParams = {
      action: "seasononholdlist",
      viewtype: value,
    };
    dispatch(actions.callSeasonOnHoldList(queryParams));
  };

  return (
    <div>
      <SweetAlert2
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
        customClass="custom-swal"
      />
      <LotteryOnHoldDetail
        setSwalProps={setSwalProps}
        isLotteryOnHoldDetailsVisible={lotteryOnHoldDetailsVisible}
        setLotteryOnHoldDetailsVisible={setLotteryOnHoldDetailsVisible}
        lotteryItemID={lotteryItemID}
        viewType={viewType}
        onClose={() => setLotteryOnHoldDetailsVisible(false)}
      />
      <div className="d-flex my-2"></div>
      {lotteryState &&
        lotteryState.seasonOnHoldList &&
        lotteryState.seasonOnHoldList.seasonOnHoldViewTypes && (
          <select
            className="form-control  mx-2 w-50"
            value={viewType}
            onChange={(e) => handleViewTypeChange(e.target.value)}
          >
            {lotteryState.seasonOnHoldList.seasonOnHoldViewTypes.map(
              (type, index) => (
                <option key={index} value={type.viewtype}>
                  {type.viewtypedscp}
                </option>
              )
            )}
          </select>
        )}

      <div className=" mt-2" style={{ minHeight: "600px" }}>
        <div className="d-flex justify-content-center text-font2">
          <table style={{ width: "100%" }}>
            <thead className="custom-border">
              <tr>
                <th className="col-3 ps-1 ">
                  {intl.formatMessage({ id: "date" })}
                </th>
                <th className="col-2 text-start text-nowrap pe-1 ">
                  {intl.formatMessage({ id: "action" })}
                </th>
                <th className="col-2 text-end text-nowrap ">
                  {intl.formatMessage({ id: "holdAmount" })}
                </th>
                <th className="col-2 text-end text-nowrap pe-1 ">
                  {intl.formatMessage({ id: "seasonLeft" })}
                </th>
              </tr>
            </thead>

            <tbody>
              {lotteryState &&
              lotteryState.seasonOnHoldList &&
              lotteryState.seasonOnHoldList.seasonOnHoldLists &&
              lotteryState.seasonOnHoldList.seasonOnHoldLists.length > 0 ? (
                lotteryState.seasonOnHoldList.seasonOnHoldLists.map(
                  (list, index) => (
                    <tr
                      className="custom-border"
                      onClick={() => {
                        setLotteryOnHoldDetailsVisible(true);
                        setLotteryItemID(list.effrelateid);
                      }}
                    >
                      <td className="col-3 ps-1 ">
                        {list.orderdate}
                        <br />
                        {list.ordertime}
                      </td>
                      <td
                        className="col-2 text-start text-nowrap "
                        style={{
                          color: list.action === "Void" ? "red" : "",
                        }}
                      >
                        {list.action}
                      </td>
                      <td className="col-2 text-end text-nowrap ">
                        {list.holdamount}
                      </td>
                      <td className="col-2 text-end text-nowrap pe-1 ">
                        {list.seasonleft}
                      </td>
                    </tr>
                  )
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
