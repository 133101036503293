import axios from "axios";
import { authGet, authPost } from "../../common/redux/ApiClient";

const baseUrl = "http://35.240.204.237/w188_api";

export function fetchUpdatePassword(queryParams) {
  return authPost(`${baseUrl}/Member01.ashx`, queryParams);
}
export function fetchRedirectAgent(queryParams) {
  return authPost(`${baseUrl}/Member4.ashx`, queryParams);
}
export function fetchUpdateLanguage(queryParams) {
  return authPost(`${baseUrl}/Member5.ashx`, queryParams);
}
