import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import SweetAlert2 from "react-sweetalert2";
import { toAbsoluteUrl } from "../../../../utils/CommonHelper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Overlay } from "../../../common/components/Overlay";
import Skeleton from "react-loading-skeleton";
import * as actions from "../../redux/WalletAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import { TransferForm } from "./TransferForm";
import { callMemberBalance } from "../../../home/redux/HomeAction";

export const TransferDialog = ({ dismissBtnRef }) => {
  const [swalProps, setSwalProps] = useState({});
  const intl = useIntl();
  const dispatch = useDispatch();
  const formikRef = useRef();
  const receiptRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const walletState = useSelector(({ wallet }) => wallet, shallowEqual);

  const saveValues = (queryParams) => {
    setIsLoading(true);
    const newQueryParams = {
      action: "membertomember",
      selusername: queryParams.selusername,
      amount: queryParams.amount,
    };
    dispatch(actions.transferToMember(newQueryParams)).then((resp) => {
      resp = resp.data;

      if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
        setSwalProps({
          show: true,
          icon: "success",
          title: intl.formatMessage({ id: "success" }),
          text: intl.formatMessage({ id: "transferSuccessfully" }),
          onResolve: () => {
            const queryParams = {
              action: "memberbalance",
            };
            dispatch(callMemberBalance(queryParams));
            formikRef.current.resetForm();
            if (dismissBtnRef) {
              dismissBtnRef.current.click();
            }
            setIsLoading(false);
          },
        });
      } else {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
        setIsLoading(false);
      }
    });
  };
  return (
    <div
      style={{
        backgroundColor: "#1c1e23",
        borderRadius: "5px",
      }}
    >
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      <div
        style={{
          color: "white",
          width: "95%",
          margin: "5px auto",
        }}
      >
        <div className="my-4">
          <TransferForm
            formikRef={formikRef}
            receiptRef={receiptRef}
            saveValues={saveValues}
          />
        </div>
        <button
          type="button"
          disabled={isLoading}
          style={{ color: "black", backgroundColor: "#f5be09" }}
          className="btn btn-prim fw-bold px-3 w-100 mb-4 mt-2"
          onClick={() => formikRef.current.submitForm()}
        >
          <div className="d-flex align-items-center justify-content-center text-font3">
            {isLoading && (
              <div
                className="spinner-border text-dark"
                role="status"
                style={{ scale: "0.75" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            )}

            {isLoading
              ? intl.formatMessage({ id: "loading" })
              : intl.formatMessage({ id: "transfer" })}
          </div>
        </button>
      </div>
    </div>
  );
};
