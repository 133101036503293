import { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/LotteryAction";
import { useIntl } from "react-intl";

export const VoucherModal = ({
  isShow,
  setIsShow,
  setPromo,
  promo,
  setIsLoading,
  setSwalProps,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const lotteryState = useSelector(({ lottery }) => lottery, shallowEqual);

  useEffect(() => {
    if (isShow) {
      setIsLoading(true);
      dispatch(actions.fetchVoucherList({ action: "voucheractivelist" }))
        .then(() => setIsLoading(false))
        .catch(() => setIsLoading(false));
    }

    return () => dispatch(actions.clearVoucherList());
  }, [isShow]);

  return (
    <Modal
      centered
      data-bs-theme="dark"
      show={isShow}
      onHide={() => setIsShow(false)}
    >
      <Modal.Header
        closeButton
        style={{ background: "rgb(28, 30, 35)", color: "white" }}
      >
        <Modal.Title>{intl.formatMessage({ id: "voucher" })}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className="p-2"
        style={{ background: "rgb(28, 30, 35)", color: "white" }}
      >
        {lotteryState.voucherList?.voucherActiveLists ? (
          lotteryState.voucherList?.voucherActiveLists.length > 0 ? (
            lotteryState.voucherList?.voucherActiveLists.map(
              (voucher, index) => (
                <div
                  className="rounded p-2"
                  style={{
                    background: "rgba(255, 255, 255,0.1)",
                    ...(promo?.promotionid === voucher.promotionid &&
                    promo?.voucherid === voucher.voucherid
                      ? { border: "2px solid #f5be09" }
                      : {}),
                  }}
                  key={index}
                  onClick={() => {
                    setSwalProps({
                      show: true,
                      position: "bottom",
                      text: intl.formatMessage({
                        id:
                          promo?.promotionid === voucher.promotionid &&
                          promo?.voucherid === voucher.voucherid
                            ? "voucherRemoved"
                            : "voucherApplied",
                      }),
                      timer: 1000,
                      showConfirmButton: false,
                      toast: true,
                      width: "13.2rem",
                    });
                    setPromo((prev) =>
                      prev?.promotionid === voucher.promotionid &&
                      prev?.voucherid === voucher.voucherid
                        ? undefined
                        : voucher
                    );
                    setIsShow(false);
                  }}
                >
                  <div className="fw-bold mb-2 fs-5">
                    {voucher.promotionname}
                  </div>
                  <div>
                    {intl.formatMessage({ id: "worth" })} : {voucher.worth}
                  </div>
                  <div style={{ color: "gray" }}>
                    <div>
                      {intl.formatMessage({ id: "limit" })} :{" "}
                      {voucher.limitcount}
                    </div>
                    <div>
                      {intl.formatMessage({ id: "maxPrice" })} :{" "}
                      {voucher.limitamount}
                    </div>
                    <div>
                      {intl.formatMessage({ id: "company" })} :{" "}
                      {voucher.limitcompany}
                    </div>
                  </div>
                </div>
              )
            )
          ) : (
            <div className="p-4 text-center">
              {intl.formatMessage({ id: "noVoucherFound" })}
            </div>
          )
        ) : null}
      </Modal.Body>
    </Modal>
  );
};
