import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../redux/LotteryAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import SweetAlert2 from "react-sweetalert2";
import { Overlay } from "../../../common/components/Overlay";
import Skeleton from "react-loading-skeleton";
import { DateToDateString, GetBeforeDate } from "../../../../utils/DateHelper";
import { LotteryHistoryDetail } from "./lotteryHistoryDetail/LotteryHistoryDetail";
import DateRangeComp from "../../../common/components/DateRangeComp";
import { LotteryHistoryTypeConst } from "../../../../constants/LotteryNavConst";
import { LotteryOnHoldDetail } from "./lotteryOnHoldDetail/LotteryOnHoldDetail";

export const LotteryHistory = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [lotteryHistoryDetailsVisible, setLotteryHistoryDetailsVisible] =
    useState(false);
  const [lotteryOrderID, setLotteryOrderID] = useState("");
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [lotteryType, setLotteryType] = useState(
    LotteryHistoryTypeConst.ACTIVE
  );
  const [swalProps, setSwalProps] = useState({});

  const lotteryState = useSelector(({ lottery }) => lottery, shallowEqual);

  useEffect(() => {
    const queryParams = {
      action: "vbosssliplist",
      fromdate: fromDate,
      todate: toDate,
    };
    dispatch(actions.vBossSlipList(queryParams)).then((resp) => {
      resp = resp.data;
      if (parseInt(resp.code) === ApiStatusTypeEnum.COMMON_ERROR) {
        setSwalProps({
          show: true,
          icon: "error",
          title: intl.formatMessage({ id: "error" }),
          text: resp.message,
        });
      }
    });
  }, [toDate]);

  useEffect(() => {
    const queryParams = {
      action: "vbossactiveslip",
    };
    dispatch(actions.vBossActiveSlipList(queryParams));
  }, []);

  const handleDateChange = (start, end) => {
    setFromDate(start);
    setToDate(end);
  };

  return (
    <div>
      <SweetAlert2
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
        customClass="custom-swal"
      />
      {lotteryState.actionsLoading && <Overlay />}
      <LotteryHistoryDetail
        setSwalProps={setSwalProps}
        isLotteryHistoryDetailsVisible={lotteryHistoryDetailsVisible}
        setLotteryHistoryDetailsVisible={setLotteryHistoryDetailsVisible}
        lotteryOrderID={lotteryOrderID}
        fromDate={fromDate}
        toDate={toDate}
        onClose={() => setLotteryHistoryDetailsVisible(false)}
      />
      <div className="d-flex justify-content-center align-items-center my-2">
        {Object.entries(LotteryHistoryTypeConst).map(([key, value], index) => (
          <div
            key={index}
            className={`p-2 cursor-pointer ws-nowrap text-center`}
            style={{
              flex: 1,
              maxWidth: "50%",
              background: "rgba(255,255,255,0.1)",
              color: lotteryType === value ? "rgb(245, 190, 9)" : "",
              borderRight:
                index === 0 ? "1px solid rgba(255,255,255,0.2)" : "none",
            }}
            onClick={() => {
              setLotteryType(value);
            }}
          >
            {intl.formatMessage({ id: value })}
          </div>
        ))}
      </div>

      {lotteryType === "slipHistory" && (
        <>
          <div className="w-100">
            <DateRangeComp
              initialRange={[
                { startDate: fromDate, endDate: toDate, key: "selection" },
              ]}
              onDateChange={handleDateChange}
            />
          </div>
        </>
      )}
      {lotteryType === "slipHistory" ? (
        <>
          <div
            className=" mt-2"
            style={{ overflowY: "auto", maxHeight: "68vh" }}
          >
            <div className="d-flex justify-content-center text-font2">
              <table style={{ width: "100%" }}>
                <thead className="custom-border">
                  <tr>
                    <th className="col-3 ps-1">
                      {intl.formatMessage({ id: "date" })}
                    </th>
                    <th className="col-2 text-start text-nowrap pe-1">
                      {intl.formatMessage({ id: "status" })}
                    </th>
                    <th className="col-2 text-end text-nowrap">
                      {intl.formatMessage({ id: "bet" })}
                    </th>
                    <th className="col-2 text-end text-nowrap pe-1">
                      {intl.formatMessage({ id: "chip" })}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {lotteryState &&
                  lotteryState.vBossSlipList &&
                  lotteryState.vBossSlipList.vbossSlipLists &&
                  lotteryState.vBossSlipList.vbossSlipLists.length > 0 ? (
                    lotteryState.vBossSlipList.vbossSlipLists.map(
                      (list, index) => (
                        <tr
                          className="custom-border"
                          onClick={() => {
                            setLotteryHistoryDetailsVisible(true);
                            setLotteryOrderID(list.orderid);
                          }}
                        >
                          <td className="col-3 ps-1">{list.buydate}</td>
                          <td
                            className="col-2 text-start text-nowrap"
                            style={{
                              color: list.orderstatus === "void" ? "red" : "",
                            }}
                          >
                            {list.statusmessage}
                          </td>
                          <td className="col-2 text-end text-nowrap">
                            {list.totalbet}
                          </td>
                          <td className="col-2 text-end text-nowrap pe-1">
                            {list.totalwin}
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <></>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className=" mt-2"
            style={{ overflowY: "auto", maxHeight: "68vh" }}
          >
            <div className="px-1">
              {lotteryState?.vBossActiveSlipList?.vbossSlipLists &&
              lotteryState.vBossActiveSlipList.vbossSlipLists.length > 0 ? (
                lotteryState.vBossActiveSlipList.vbossSlipLists.map(
                  (list, index) => (
                    <div
                      key={index}
                      className="my-1 w-100"
                      style={{
                        borderRadius: "5px",
                        backgroundColor: "#1c1e23",
                      }}
                      onClick={() => {
                        setLotteryHistoryDetailsVisible(true);
                        setLotteryOrderID(list.orderid);
                      }}
                    >
                      <div className="mx-2 my-2 p-2">
                        <div className="d-flex align-items-center justify-content-between">
                          <div>
                            <div className="mb-1">
                              {intl.formatMessage({ id: "drawDate" })} :{" "}
                              {list.drawdate}
                            </div>
                            <div
                              className="text-font2"
                              style={{ color: "gray" }}
                            >
                              <div>
                                {intl.formatMessage({ id: "betting" })} :{" "}
                                {list.totalbet}
                              </div>
                              <div>
                                {intl.formatMessage({ id: "purchaseDate" })} :{" "}
                                {list.buydate}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center justify-content-center text-font2">
                            <div className="rounded border px-2 py-1">
                              {list.statusmessage}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )
              ) : (
                <div
                  className="my-3 d-flex align-items-center"
                  style={{ color: "gray" }}
                >
                  {intl.formatMessage({ id: "currentlyNoRecord" })}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
