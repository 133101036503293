import { createSlice } from "@reduxjs/toolkit";
import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";

const lotteryState = {
  lotteryResultUI: null,
  voucherList: null,
  lotteryPrizeUI: null,
  buyLotteryList: null,
  vBossSlipList: null,
  vBossActiveSlipList: null,
  lotteryHistoryDetail: null,
  voidLottery: null,
  reorderLottery: null,
  seasonOnHoldList: null,
  lotteryOnHoldDetail: null,
  voidSeasonOnHold: null,
  error: null,
  listLoading: false,
  actionsLoading: false,
  searchLoading: false,
  isRefresh: false,
};

export const lotterySlice = createSlice({
  name: "lottery",
  initialState: lotteryState,
  reducers: {
    catchError: (state, action) => {
      state.error = action.payload.error;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = false;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = false;
      }
    },
    throwError: (state, action) => {
      state.error = action.payload.data.error;
    },
    startCall: (state, action) => {
      state.isRefresh = false;
      state.error = null;

      if (action.payload.callType === ReduxCallTypesConst.LIST) {
        state.listLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.ACTION) {
        state.actionsLoading = true;
      } else if (action.payload.callType === ReduxCallTypesConst.DETAIL) {
        state.searchLoading = true;
      }
    },
    clearError: (state) => {
      state.error = null;
    },
    LotteryResultUIFetched: (state, action) => {
      state.listLoading = false;
      state.lotteryResultUI = action.payload.data ?? {};
    },
    LotteryPrizeUIFetched: (state, action) => {
      state.listLoading = false;
      state.lotteryPrizeUI = action.payload.data ?? {};
    },
    buyLotteryListFetched: (state, action) => {
      state.listLoading = false;
      state.buyLotteryList = action.payload.data ?? {};
    },
    vBossSlipListFetched: (state, action) => {
      state.listLoading = false;
      state.vBossSlipList = action.payload.data ?? {};
    },
    voucherListFetched: (state, action) => {
      state.actionsLoading = false;
      state.voucherList = action.payload.data ?? {};
    },
    clearVoucherList: (state) => {
      state.voucherList = null;
    },
    vBossActiveSlipListFetched: (state, action) => {
      state.listLoading = false;
      state.vBossActiveSlipList = action.payload.data ?? {};
    },
    lotteryHistoryDetailFetched: (state, action) => {
      state.actionsLoading = false;
      state.lotteryHistoryDetail = action.payload.data ?? {};
    },
    clearLotteryHistoryDetail: (state) => {
      state.lotteryHistoryDetail = lotteryState.lotteryHistoryDetail;
    },
    voidLotteryFetched: (state, action) => {
      state.actionsLoading = false;
      state.voidLottery = action.payload.data ?? {};
    },
    reorderLotteryFetched: (state, action) => {
      state.actionsLoading = false;
      state.reorderLottery = action.payload.data ?? {};
    },
    seasonOnHoldListFetched: (state, action) => {
      state.listLoading = false;
      state.seasonOnHoldList = action.payload.data ?? {};
    },
    lotteryOnHoldDetailFetched: (state, action) => {
      state.listLoading = false;
      state.lotteryOnHoldDetail = action.payload.data ?? {};
    },
    voidSeasonOnHoldFetched: (state, action) => {
      state.actionsLoading = false;
      state.voidSeasonOnHold = action.payload.data ?? {};
    },
  },
});
