import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import SweetAlert2 from "react-sweetalert2";
import { toAbsoluteUrl } from "../../../utils/CommonHelper";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Overlay } from "../../common/components/Overlay";
import Skeleton from "react-loading-skeleton";
// import * as actions from "../../../redux/SettingAction";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import { WalletTypeConst } from "../../../constants/WalletConst";
import { DepositDialog } from "./deposit/Deposit";
import { Withdraw } from "./withdraw/Withdraw";
import { History } from "./history/History";
import { useLocation } from "react-router-dom";
import { TransferDialog } from "./transfer/Transfer";

export const Wallet = ({}) => {
  const [swalProps, setSwalProps] = useState({});
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const [walletType, setWalletType] = useState(WalletTypeConst.DEPOSIT);
  const walletState = useSelector(({ wallet }) => wallet, shallowEqual);
  useEffect(() => {
    const walletType = location.state?.walletType || WalletTypeConst.DEPOSIT;
    setWalletType(walletType);
  }, [location.state]);

  return (
    <div>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />

      <div className="d-flex my-2 justify-content-center">
        {Object.entries(WalletTypeConst).map(([key, value], index) => (
          <div
            key={index}
            className={`cursor-pointer me-3 rounded ws-nowrap text-center ${
              walletType === value ? " fw-bold " : ""
            }`}
            style={{
              color: walletType === value ? "rgb(245, 190, 9)" : "",
              textDecoration: walletType === value ? "underline" : "",
            }}
            onClick={() => {
              setWalletType(value);
            }}
          >
            {intl.formatMessage({ id: value })}
          </div>
        ))}
      </div>
      <div>
        {walletType === WalletTypeConst.DEPOSIT ? (
          <DepositDialog />
        ) : walletType === WalletTypeConst.WITHDRAW ? (
          <Withdraw />
        ) : walletType === WalletTypeConst.TRANSFER ? (
          <TransferDialog />
        ) : (
          <History />
        )}
      </div>
    </div>
  );
};
