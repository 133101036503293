import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../redux/PromotionAction";
import { useIntl } from "react-intl";
import { Modal } from "react-bootstrap";
import { FiArrowRight } from "react-icons/fi";

export const Promotion = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const promotionState = useSelector(
    ({ promotion }) => promotion,
    shallowEqual
  );

  const [promoDetail, setPromoDetail] = useState();

  useEffect(() => {
    dispatch(actions.fetchPromotionList({ action: "promotionlist" }));
  }, []);

  return (
    <>
      <Modal
        centered
        data-bs-theme="dark"
        show={promoDetail ? true : false}
        onHide={() => setPromoDetail(undefined)}
      >
        <Modal.Header
          closeButton
          style={{ background: "rgb(28, 30, 35)", color: "white" }}
        >
          <Modal.Title>{intl.formatMessage({ id: "promotion" })}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className="p-0"
          style={{ background: "rgb(28, 30, 35)", color: "white" }}
        >
          {promoDetail && (
            <iframe
              src={promoDetail}
              className="border-none w-100"
              frameBorder={0}
              style={{ height: "65vh" }}
            />
          )}
        </Modal.Body>
      </Modal>
      <div className="d-flex flex-column gap-4 p-2 align-items-stretch">
        {promotionState.promotionList?.promotionLists ? (
          promotionState.promotionList?.promotionLists.length > 0 ? (
            promotionState.promotionList?.promotionLists.map(
              (promotion, index) => (
                <div
                  className="cursor-pointer rounded shadow p-2"
                  key={index}
                  style={{ background: "rgb(28, 30, 35)" }}
                  onClick={() => setPromoDetail(promotion.promotionhtml)}
                >
                  <img src={promotion.promotionimg} className="w-100 mb-2" />
                  <div className="fw-bold mb-2">{promotion.promotionname}</div>
                  <div style={{ color: "gray" }}>
                    {intl.formatMessage({ id: "worth" })} : {promotion.worth}
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    {promotion.eventenddate !== "" ? (
                      <div style={{ color: "gray" }}>
                        {intl.formatMessage({ id: "expiration" })} :{" "}
                        {promotion.eventenddate}
                      </div>
                    ) : (
                      <div></div>
                    )}
                    <div
                      className="text-end fw-bold d-flex align-items-center justify-content-end"
                      style={{ color: "#f5be09" }}
                    >
                      {intl.formatMessage({ id: "detail" })}{" "}
                      <FiArrowRight style={{ color: "#f5be09" }} size={20} />
                    </div>
                  </div>
                </div>
              )
            )
          ) : (
            <div className="p-4 text-center">
              {intl.formatMessage({ id: "noPromotionFound" })}
            </div>
          )
        ) : null}
      </div>
    </>
  );
};
