import { useState } from "react";
import { useIntl } from "react-intl";
import {
  HistoryStatusColorConst,
  HistoryStatusConst,
  historyDurationList,
} from "../../../constants/HistoryConst";
import * as actions from "../redux/ReportAction";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import SweetAlert2 from "react-sweetalert2";
import { ApiStatusTypeEnum } from "../../../enumeration/ApiStatusTypeEnum";
import DateRangeComp from "../../common/components/DateRangeComp";
import { useNavigate } from "react-router-dom";
import { WalletTypeConst } from "../../../constants/WalletConst";
import { LotteryHistoryTypeConst } from "../../../constants/LotteryNavConst";
import { LotteryHistoryDetail } from "../../lottery/component/lotteryHistory/lotteryHistoryDetail/LotteryHistoryDetail";
import { LotteryOnHoldDetail } from "../../lottery/component/lotteryHistory/lotteryOnHoldDetail/LotteryOnHoldDetail";
import { Overlay } from "../../common/components/Overlay";

export const Report = () => {
  const intl = useIntl();
  const [swalProps, setSwalProps] = useState({});
  const dispatch = useDispatch();
  const [dayType, setDayType] = useState("1");
  const [viewType, setViewType] = useState("all");
  const navigate = useNavigate();
  const [lotteryHistoryDetailsVisible, setLotteryHistoryDetailsVisible] =
    useState(false);
  const [lotteryOnHoldDetailsVisible, setLotteryOnHoldDetailsVisible] =
    useState(false);
  const [lotteryOrderID, setLotteryOrderID] = useState("");
  const [lotteryItemID, setLotteryItemID] = useState("");
  const reportState = useSelector(({ report }) => report, shallowEqual);
  const lotteryState = useSelector(({ lottery }) => lottery, shallowEqual);

  useEffect(() => {
    const queryParams = {
      action: "transactiontionreportV2",
      days: dayType,
      viewtype: viewType,
    };
    dispatch(actions.transactionReportList(queryParams));
  }, []);

  const handleDateChange = (value) => {
    setDayType(value);
    const queryParams = {
      action: "transactiontionreportV2",
      days: value,
      viewtype: viewType,
    };
    dispatch(actions.transactionReportList(queryParams));
  };

  const handleViewTypeChange = (value) => {
    setViewType(value);
    const queryParams = {
      action: "transactiontionreportV2",
      days: dayType,
      viewtype: value,
    };
    dispatch(actions.transactionReportList(queryParams));
  };

  const handleHistoryClick = () => {
    navigate("/home", {
      state: {
        categoryIndex: 7,
        dayType: dayType,
        walletType: WalletTypeConst.PROGRESS,
      },
    });
    sessionStorage.setItem("categoryIndex", 7);
  };

  return (
    <div style={{ width: "100%" }}>
      <SweetAlert2
        customClass="custom-swal"
        onResolve={() => {
          setSwalProps({});
        }}
        {...swalProps}
      />
      {lotteryState.actionsLoading && <Overlay />}
      <LotteryHistoryDetail
        setSwalProps={setSwalProps}
        isLotteryHistoryDetailsVisible={lotteryHistoryDetailsVisible}
        setLotteryHistoryDetailsVisible={setLotteryHistoryDetailsVisible}
        lotteryOrderID={lotteryOrderID}
        onClose={() => setLotteryHistoryDetailsVisible(false)}
      />
      <LotteryOnHoldDetail
        setSwalProps={setSwalProps}
        isLotteryOnHoldDetailsVisible={lotteryOnHoldDetailsVisible}
        setLotteryOnHoldDetailsVisible={setLotteryOnHoldDetailsVisible}
        lotteryItemID={lotteryItemID}
        onClose={() => setLotteryOnHoldDetailsVisible(false)}
      />
      <div className="d-flex px-2">
        <select
          className="form-control  mx-2"
          value={dayType}
          onChange={(e) => handleDateChange(e.target.value)}
        >
          {historyDurationList.map((day, index) => (
            <option key={index} value={day}>
              {day} {intl.formatMessage({ id: "days" })}
            </option>
          ))}
        </select>
        {reportState.transactionReportList?.transactionReportsV2 && (
          <select
            className="form-control  mx-2"
            value={viewType}
            onChange={(e) => handleViewTypeChange(e.target.value)}
          >
            {reportState.transactionReportList.transactionViewTypesV2.map(
              (type, index) => (
                <option key={index} value={type.viewtype}>
                  {type.viewtypedscp}
                </option>
              )
            )}
          </select>
        )}
      </div>
      <div className=" mt-2" style={{ minHeight: "600px" }}>
        <div className="d-flex justify-content-center text-font2">
          <table style={{ width: "100%" }}>
            <thead className="custom-border">
              <tr>
                <th className="col-3 ps-1 ">
                  {intl.formatMessage({ id: "dateTime" })}
                </th>
                <th className="col-2 text-start text-nowrap ">
                  {intl.formatMessage({ id: "action" })}
                </th>
                <th className="col-2 text-start text-nowrap pe-1 ">
                  {intl.formatMessage({ id: "remark" })}
                </th>
                <th className="col-2 text-end text-nowrap pe-1 ">
                  {intl.formatMessage({ id: "cash" })}
                </th>
                <th className="col-2 text-end text-nowrap pe-1 ">
                  {intl.formatMessage({ id: "amount" })}
                </th>
              </tr>
            </thead>

            <tbody>
              {reportState.transactionReportList?.transactionReportsV2 &&
              reportState.transactionReportList.transactionReportsV2.length >
                0 ? (
                reportState.transactionReportList.transactionReportsV2.map(
                  (list, index) => (
                    <tr
                      className="custom-border"
                      onClick={() => {
                        if (list.reporttype === "4dhold") {
                          setLotteryItemID(list.effrelateid);
                          setLotteryOnHoldDetailsVisible(true);
                        } else if (list.reporttype === "4dslip") {
                          setLotteryOrderID(list.orderid);
                          setLotteryHistoryDetailsVisible(true);
                        } else {
                          handleHistoryClick();
                        }
                      }}
                    >
                      <td className="col-3 ps-1 ">
                        {list.reportdate} {list.reporttime}
                      </td>
                      <td className="col-2 text-start text-nowrap ">
                        {list.action}
                      </td>
                      <td className="col-2 text-start text-nowrap pe-1 ">
                        {list.remark}
                      </td>
                      <td
                        className="col-2 text-end text-nowrap pe-1 "
                        style={{
                          color: list.cash < 0 ? "red" : "",
                        }}
                      >
                        {list.cash}
                      </td>
                      <td className="col-2 text-end text-nowrap pe-1 ">
                        {list.amount}
                      </td>
                    </tr>
                  )
                )
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
