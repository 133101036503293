import { ReduxCallTypesConst } from "../../../constants/ReduxCallTypesConst";
import * as requestToApi from "./LotteryCRUD";
import { lotterySlice } from "./LotterySlice";

const { actions } = lotterySlice;
// result
export const clearVoucherList = () => (dispatch) => {
  dispatch(actions.clearVoucherList());
};

export const getLotteryResultUI = () => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchLotteryResultUI()
    .then((response) => {
      if (response) {
        if (!response.data.isAbort) {
          dispatch(actions.LotteryResultUIFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: LotteryResultUIFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

export const fetchVoucherList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .getVoucherList(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.voucherListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: getVoucherList");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

// prize
export const getLotteryPrizeUI = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchLotteryPrizeUI(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.LotteryPrizeUIFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: LotteryPrizeUIFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
// buyLottery

export const buyLottery = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchLotteryUI(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.buyLotteryListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: buyLotteryListFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};

// lotteryList
export const vBossSlipList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchLotteryUI(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.vBossSlipListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: vBossSlipListFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
export const vBossActiveSlipList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchLotteryUI(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.vBossActiveSlipListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: vBossActiveSlipListFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
export const clearLotteryHistoryDetail = () => (dispatch) => {
  dispatch(actions.clearLotteryHistoryDetail());
};

export const getLotteryHistoryDetail = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .fetchLotteryUI(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(
            actions.lotteryHistoryDetailFetched({ data: response.data })
          );
          return response;
        }
      } else {
        throw new Error("Error api: lotteryHistoryDetailFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const voidLottery = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .fetchLotteryUI(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.voidLotteryFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: voidLotteryFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

export const reorderLottery = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .fetchLotteryUI(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.reorderLotteryFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: reorderLotteryFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};

// onHoldList
export const callSeasonOnHoldList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchLotteryUI(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.seasonOnHoldListFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: seasonOnHoldListFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
export const getLotteryOnHoldDetail = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.LIST }));

  return requestToApi
    .fetchLotteryUI(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.lotteryOnHoldDetailFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: lotteryOnHoldDetailFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.LIST })
      );
      throw error;
    });
};
export const voidSeasonOnHold = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: ReduxCallTypesConst.ACTION }));

  return requestToApi
    .fetchLotteryUI(queryParams)
    .then((response) => {
      if (response && response.data) {
        if (!response.data.isAbort) {
          dispatch(actions.voidSeasonOnHoldFetched({ data: response.data }));
          return response;
        }
      } else {
        throw new Error("Error api: voidSeasonOnHoldFetched");
      }
    })
    .catch((error) => {
      dispatch(
        actions.catchError({ error, callType: ReduxCallTypesConst.ACTION })
      );
      throw error;
    });
};
