import { useEffect, useRef, useState } from "react";
import { LotteryBetForm } from "./LotteryBetForm";
import { useIntl } from "react-intl";
import SweetAlert2 from "react-sweetalert2";
import { Overlay } from "../../../common/components/Overlay";
import { useDispatch } from "react-redux";
import * as actions from "../../redux/LotteryAction";
import { ApiStatusTypeEnum } from "../../../../enumeration/ApiStatusTypeEnum";
import {
  lotterySeasonList,
  LotteryTypeConst,
} from "../../../../constants/LotteryNavConst";
import { callMemberBalance } from "../../../home/redux/HomeAction";
import { useNavigate } from "react-router-dom";
import { LotteryHistoryDetail } from "../lotteryHistory/lotteryHistoryDetail/LotteryHistoryDetail";
import { VoucherModal } from "./VoucherModal";

export const LotteryBetDialog = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [longCodeList, setLongCodeList] = useState([]);
  const [addedCompany, setAddedCompany] = useState([]);
  const [swalProps, setSwalProps] = useState({});
  const [lotteryOnHoldDetailsVisible, setLotteryOnHoldDetailsVisible] =
    useState(false);
  const [season, setSeason] = useState(1);
  const [lotteryItemID, setLotteryItemID] = useState("");
  const [estimateTotal, setEstimateTotal] = useState(0);
  const [balance, setBalance] = useState(0);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [promo, setPromo] = useState();
  const tableContainerRef = useRef(null);
  const lotteryTypeRef = useRef(LotteryTypeConst.BASIC_LOTTERY);

  const saveValues = (longCode) => setLongCodeList([...longCodeList, longCode]);

  const useVoucher = () => {
    setIsShow(true);
  };

  useEffect(() => {
    if (longCodeList.length > 0 && season) {
      const total = parseFloat(
        longCodeList
          .map((longCode) => longCode.subTotal)
          .reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          ) * season
      ).toFixed(2);

      setEstimateTotal(total);
    }
  }, [longCodeList, season]);

  useEffect(() => {
    if (!longCodeList || longCodeList.length <= 0) {
      setAddedCompany([]);
    }
  }, [longCodeList]);

  useEffect(() => {
    const storageLongCode = JSON.parse(localStorage.getItem("longcode")) || [];
    const storageSeason = localStorage.getItem("season");

    if (storageLongCode) {
      setLongCodeList((prevLongCodeList) => [...storageLongCode]);
    }

    if (storageSeason) {
      setSeason(storageSeason);
    }
    const queryParams = {
      action: "memberbalance",
    };
    dispatch(callMemberBalance(queryParams)).then((resp) => {
      const { balance } = resp.data;
      setBalance(balance);
    });
  }, []);

  const handleSeasonChange = (value) => {
    setSeason(value);
  };
  const handleRedirectToDeposit = () => {
    navigate("/home", {
      state: {
        categoryIndex: 7,
      },
    });
    sessionStorage.setItem("categoryIndex", 7);
    localStorage.setItem("longcode", JSON.stringify(longCodeList));
    localStorage.setItem("season", season);
  };
  const confirmSubmitNumber = () => {
    setIsLoading(true);
    const longCode = longCodeList.map((longCD) => longCD.code).join("|");
    const fullLongCode = `D${season}|${longCode}`;

    if (longCode != "") {
      const queryParams = {
        action: "vbossbuy4dV2",
        betformat: fullLongCode,
        companytype: lotteryTypeRef.current,
        voucherid: promo?.voucherid ?? "",
        promotionid: promo?.promotionid ?? "",
      };
      dispatch(actions.buyLottery(queryParams)).then((resp) => {
        setIsLoading(false);
        resp = resp.data;
        if (parseInt(resp.code) === ApiStatusTypeEnum.SUCCESS) {
          setPromo(undefined);
          localStorage.removeItem("season");
          localStorage.removeItem("longcode");
          setLongCodeList([]);
          const queryParams = {
            action: "memberbalance",
          };
          dispatch(callMemberBalance(queryParams));
          setLotteryItemID(resp.orderid);
          setLotteryOnHoldDetailsVisible(true);
        } else {
          setSwalProps({
            show: true,
            icon: "error",
            title: intl.formatMessage({ id: "error" }),
            text: resp.message,
          });
        }
      });
    } else if (longCode === "" || longCode === "null") {
      setSwalProps({
        show: true,
        icon: "error",
        title: intl.formatMessage({ id: "error" }),
        text: intl.formatMessage({ id: "addNumber" }),
      });
    }
  };

  useEffect(() => {
    if (tableContainerRef.current) {
      // Scroll to the bottom of the table container
      tableContainerRef.current.scrollTop =
        tableContainerRef.current.scrollHeight;
    }
  }, [longCodeList]);
  return (
    <div>
      <VoucherModal
        isShow={isShow}
        setIsShow={setIsShow}
        setPromo={setPromo}
        promo={promo}
        setIsLoading={setIsLoading}
        setSwalProps={setSwalProps}
      />
      <LotteryHistoryDetail
        setSwalProps={setSwalProps}
        isLotteryHistoryDetailsVisible={lotteryOnHoldDetailsVisible}
        setLotteryHistoryDetailsVisible={setLotteryOnHoldDetailsVisible}
        lotteryOrderID={lotteryItemID}
        onClose={() => setLotteryOnHoldDetailsVisible(false)}
        isCallListApi={false}
      />
      {isLoading && <Overlay />}
      <SweetAlert2
        {...swalProps}
        onResolve={() => {
          setSwalProps({});
        }}
        customClass="custom-swal"
      />

      <div
        ref={tableContainerRef}
        style={{
          overflowY: "auto",
          maxHeight: "39vh",
          marginBottom: "17vh",
        }}
      >
        <table className="w-100">
          <thead className="bg-secondary border border-dark">
            <tr>
              <th className="col-1 text-start text-nowrap">
                {intl.formatMessage({ id: "no" })}
              </th>
              <th className="col-2 text-start text-nowrap">
                {intl.formatMessage({ id: "number" })}
              </th>
              <th className="col-2 text-end text-nowrap">
                {intl.formatMessage({ id: "company" })}
              </th>
              <th className="col-1 text-end text-nowrap">
                {intl.formatMessage({ id: "b" })}
              </th>
              <th className="col-1 text-end text-nowrap">
                {intl.formatMessage({ id: "s" })}
              </th>
              <th className="col-1 text-nowrap text-end text-nowrap">
                {4 + intl.formatMessage({ id: "a" })}
              </th>
              <th className="col-1 text-nowrap text-end text-nowrap">{""}</th>
            </tr>
          </thead>
          <tbody>
            {longCodeList.length > 0 && (
              <>
                {longCodeList.map((longCode, index) => (
                  <tr key={index}>
                    <td className="col-1 text-start text-nowrap">
                      {index + 1}
                    </td>
                    <td className="col-2 text-start text-nowrap">
                      <div>
                        {longCode.betType === ""
                          ? `${longCode.number}`
                          : `${longCode.number} (${longCode.betType})`}
                      </div>
                    </td>
                    <td className="col-2 text-end text-nowrap">
                      {longCode.companyList.map((companyList, index) => {
                        if (companyList.lotteryCode.length === 1) {
                          return (
                            <span key={index}>{companyList.lotteryCode}</span>
                          );
                        }
                        return (
                          <span key={index}>
                            {companyList.lotteryCode.join(", ")}
                          </span>
                        );
                      })}
                    </td>
                    <td className="col-1 text-end text-nowrap">
                      {longCode.big === "" ? 0 : longCode.big}
                    </td>
                    <td className="col-1 text-end text-nowrap">
                      {longCode.small === "" ? 0 : longCode.small}
                    </td>
                    <td className="col-1 text-nowrap text-end text-nowrap">
                      {longCode.fourA === "" ? 0 : longCode.fourA}
                    </td>
                    <td className="col-1 text-nowrap text-end text-nowrap">
                      <i
                        className="text-danger bi bi-x-circle-fill cursor-pointer text-font3"
                        onClick={() => {
                          setSwalProps({
                            show: true,
                            showCancelButton: true,
                            showConfirmButton: true,
                            confirmButtonText: intl.formatMessage({
                              id: "confirm",
                            }),
                            denyButtonText: intl.formatMessage({
                              id: "remove",
                            }),
                            icon: "info",
                            title: intl.formatMessage({ id: "alert" }),
                            text: intl.formatMessage({
                              id: "confirmRemoveNumber",
                            }),
                            onConfirm: () => {
                              setSwalProps({});
                              setLongCodeList(
                                longCodeList.filter(
                                  (longCd) => longCd.key !== longCode.key
                                )
                              );
                            },
                          });
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </div>

      <div
        className="w-100"
        style={{
          position: "fixed",
          bottom: 160,
        }}
      >
        <div
          className="mb-2 d-flex justify-content-between align-items-center"
          style={{ borderTop: "1px solid grey" }}
        >
          <div>
            <select
              className="form-control1 my-1"
              value={season}
              onChange={(e) => handleSeasonChange(e.target.value)}
              onClick={(e) => {
                if (longCodeList.length >= 1) {
                  e.preventDefault();
                  setSwalProps({
                    show: true,
                    icon: "info",
                    title: intl.formatMessage({ id: "alert" }),
                    text: intl.formatMessage({
                      id: `errorChangeSeason`,
                    }),
                  });
                }
              }}
              style={{
                borderRadius: "5px",
                color: longCodeList.length >= 1 ? "rgb(245, 190, 9)" : "white",
              }}
            >
              {lotterySeasonList.map((day, index) => (
                <option key={index} value={day}>
                  {day} {intl.formatMessage({ id: "season" })}
                </option>
              ))}
            </select>
          </div>
          {longCodeList.length > 0 ? (
            <div className="d-flex">
              {intl.formatMessage({ id: "est" })}
              <span className="mx-2" style={{ color: "rgb(245, 190, 9)" }}>
                {longCodeList.length > 0
                  ? parseFloat(
                      longCodeList
                        .map((longCode) => longCode.subTotal)
                        .reduce(
                          (accumulator, currentValue) =>
                            accumulator + currentValue,
                          0
                        ) * season
                    ).toFixed(2)
                  : "0.00"}
              </span>
              <div
                onClick={useVoucher}
                style={{ color: "rgb(245, 190, 9)" }}
                className="me-2 cursor-pointer"
              >
                (
                {intl.formatMessage({
                  id: promo ? "voucherActivated" : "useVoucher",
                })}
                )
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-end">
              {intl.formatMessage({ id: "est" })}
              <div className="mx-2" style={{ color: "rgb(245, 190, 9)" }}>
                0.00
              </div>
              <div
                onClick={useVoucher}
                style={{ color: "rgb(245, 190, 9)" }}
                className="me-2 cursor-pointer"
              >
                (
                {intl.formatMessage({
                  id: promo ? "voucherActivated" : "useVoucher",
                })}
                )
              </div>
            </div>
          )}
        </div>
        <div>
          <LotteryBetForm
            saveValues={saveValues}
            lotteryTypeRef={lotteryTypeRef}
            addedCompany={addedCompany}
            setAddedCompany={setAddedCompany}
          />
        </div>
      </div>

      <div
        style={{
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "25%",
        }}
      >
        <table style={{ textAlign: "center" }}>
          <tr style={{ height: "4vh" }}>
            <td
              style={{
                border: "2px solid grey",
                width: "25%",
                height: "40px",
                background: longCodeList.length > 0 ? "green" : "grey",
                color: "white",
                cursor: longCodeList.length > 0 ? "pointer" : "not-allowed",
                opacity: longCodeList.length > 0 ? 1 : 0.5,
              }}
              onClick={() => {
                if (longCodeList.length > 0) {
                  const parsedBalance = parseFloat(balance);
                  const parsedEstimateTotal = parseFloat(estimateTotal);
                  if (parsedBalance >= parsedEstimateTotal) {
                    setSwalProps({
                      show: true,
                      showCancelButton: true,
                      showConfirmButton: true,
                      confirmButtonText: intl.formatMessage({
                        id: "confirm",
                      }),
                      denyButtonText: intl.formatMessage({
                        id: "cancel",
                      }),
                      icon: "info",
                      title: intl.formatMessage({ id: "alert" }),
                      text: intl.formatMessage({
                        id: "confirmSubmitNumber",
                      }),
                      onConfirm: () => {
                        setSwalProps({});
                        confirmSubmitNumber();
                      },
                    });
                  } else {
                    setSwalProps({
                      show: true,
                      icon: "info",
                      title: intl.formatMessage({ id: "alert" }),
                      text: intl.formatMessage({
                        id: `InsufficientBal`,
                      }),
                      showCancelButton: true,
                      showConfirmButton: true,
                      confirmButtonText: intl.formatMessage({
                        id: "confirm",
                      }),
                      denyButtonText: intl.formatMessage({ id: "cancel" }),
                      onConfirm: () => {
                        handleRedirectToDeposit();
                      },
                    });
                  }
                }
              }}
            >
              {isLoading
                ? intl.formatMessage({ id: "loading" })
                : intl.formatMessage({ id: "bet" })}
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};
