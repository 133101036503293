import axios from "axios";
import { authGet1, authPost } from "../../common/redux/ApiClient";

const baseUrl = "http://35.240.204.237/w188_api";

export function fetchLotteryResultUI() {
  return authGet1(
    `http://35.240.204.237/ok99memberApi/api/Member5/LotteryResultUI`,
    {}
  );
}
export function fetchLotteryPrizeUI(queryParams) {
  return authPost(`${baseUrl}/Member2.ashx`, queryParams);
}
export function fetchLotteryUI(queryParams) {
  return authPost(`${baseUrl}/Member4.ashx`, queryParams);
}

export function getVoucherList(queryParams) {
  return authPost(`${baseUrl}/Member6.ashx`, queryParams);
}
